.sf-a-preview-item{
    display: flex;
    width: 280px;
    max-height: 450px;
    padding: 10px;
    border-radius: 5px;
    flex-direction: column;
    background-color: rgb(38, 38, 38);
}

.sf-a-preview-item:hover{
    background-color: rgb(54, 54, 54);
    cursor: pointer;
    opacity: 0.8;
}

.sf-a-p-i-title-cont{
    display: flex;
    gap: 10px;
}

.sf-a-p-i-price{
    padding: 4px 6px;
    color: rgb(240, 240, 240);
    font-weight: bold;
    font-size: 15px;
    letter-spacing: 1px;
    border-radius: 5px;
    background-color: rgb(118, 118, 118);
    margin: auto 0;
}

.sf-a-p-i-header{
    padding-bottom: 5px;
    display: flex;
    justify-content: space-between;
    text-align: left;
}

.sf-a-p-i-ign{
    font-size: 16px;
    margin: auto 0;
}

.account-avatar{
    margin: auto 0;
    height: 25px;
    width: 25px;
    border-radius: 5px;
}

.sf-a-p-i-overview{
    margin: 5px 0 0;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.sf-a-p-i-overview-row{
    display: flex;
    justify-content: space-between;
}

.sf-a-p-i-overview-details{
    display: flex;
    gap: 3px;
    flex-direction: column;
    justify-content: space-between;
}

.sf-a-p-i-overview-h{
    margin: auto 0;
    font-size: 12px;
    text-align: left;
}

.sf-a-p-i-overview-p{
    color: rgb(222, 222, 222);
    text-align: left;
    margin: 0;
    font-size: 14px;
}

.sf-a-p-i-overview-p>span{
    background-color: #1c2e55;
    padding: 2px 5px;
    border-radius: 2px;
}

.sf-a-p-i-overview-ul{
    margin: 0;
    display: flex;
    font-size: 12px;
    text-align: left;
    gap: 5px;
    flex-wrap: wrap;
    padding: 0;
}

.sf-a-p-i-overview-ul>li{
    list-style-type: none;
    background-color: rgb(64, 64, 64);
    font-size: 13px;
    padding: 4px 6px;
    border-radius: 5px;
}

.sf-a-p-i-f-button{
    border: 1px solid white;
    font-weight: bold;
    color: white;
    outline: none;
    padding: 10px 5px;
    width: 100%;
    font-size: 15px;
    background-color: rgb(85, 93, 99);
    border-radius: 5px;
}

.sf-a-p-i-overview-capes-cont{
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.sf-a-p-capes-container{
    border-radius: 5px;
    display: flex;
    gap: 5px;
}

.sf-a-p-cape-img
{
    width: 24px;
    height: 35px;
}

@media only screen and (max-width: 700px){
    .sf-a-preview-item{
        width: 100%;
    }

    .sf-a-p-capes-container{
        padding: 0;
    }
}